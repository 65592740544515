import DataLayerScope from 'swp-components/lib/components/common/data-layer-scope'
import Footer from 'swp-components/lib/components/footer'
import Section from 'swp-components/lib/components/section'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import BottomBanner from '@/components/bottom-banner'
import ChatShop from '@/components/chat-shop'
import ContactChannel from '@/components/contact-channel'
import CookiesConsent from '@/components/cookies-consent'
import DeviceShelf from '@/components/device-shelf'
import FaqGrid from '@/components/faq-grid'
import Header from '@/components/header'
import MobilePackage from '@/components/mobile-package'
import Newrelic from '@/components/newrelic-agent'
import PrivilegeShelf from '@/components/privilege-shelf'
import SectionHeading from '@/components/section-heading'
import Seo from '@/components/seo'
import ShortcutMenu from '@/components/shortcut-menu'
import TopBanner from '@/components/top-banner'
import VideoContent from '@/components/video-content'
import useTMHMoveToTrueDataLayer from '@/hooks/data-layers/use-tmh-move-to-true'
import useMobilePackage from '@/hooks/use-mobile-package'
import strapi from '@/strapi'
import { useEffect } from 'react'

const TrueMoveHMoveToTruePage = ({ serverData }) => {
  useEffect(() => {
    window.location.href = 'https://www.true.th/move-to-true';
  }, [])

  let [
    seo,
    headerScript,
    pageHeaders,
    brandHeaders,
    productServices,
    topBanners,
    shortcutMenus,
    privileges,
    mnpPostpaidPackages,
    mnpPrepaidPackages,
    deviceManagements,
    viewAllManagement,
    videoContent,
    contactChannel,
    faq,
    bottomBanners,
    footerHeaders,
    chatShop,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)

  let mobilePostpaidPackages = useMobilePackage('mnp-postpaid', {
    mnpPostpaidPackages,
  })

  mobilePostpaidPackages.filter(item => {
    return item.packages.length !== 0
  })

  let mobilePrepaidPackages = useMobilePackage('mnp-prepaid', {
    mnpPrepaidPackages,
  })

  mobilePrepaidPackages.filter(item => {
    return item.packages.length !== 0
  })

  let { handleMessage, createScopeProps } = useTMHMoveToTrueDataLayer()

  return (
    <>
      <Newrelic />
      <DataLayerProvider handler={handleMessage}>
        <Seo seo={seo.mnpSeo} script={headerScript.script} />
        <DataLayerScope {...createScopeProps('Header')}>
          <Header items={brandHeaders} services={productServices} />
        </DataLayerScope>
        {topBanners.length > 0 && (
          <DataLayerScope {...createScopeProps('TopBanner', { topBanners })}>
            <TopBanner banners={topBanners} />
          </DataLayerScope>
        )}
        {shortcutMenus.length > 0 && (
          <DataLayerScope {...createScopeProps('ShortcutMenu')}>
            <Section.Background className="py-4" color="light-blue">
              <ShortcutMenu color="orange" items={shortcutMenus} />
            </Section.Background>
          </DataLayerScope>
        )}

        <div className="my-5">
          {pageHeaders.mnpPage.topHeader && (
            <>
              <SectionHeading variant="underline" tag="h1">
                {pageHeaders.mnpPage.topHeader.name}
              </SectionHeading>
              <SectionHeading className="mt-3 mb-4" level="2">
                {pageHeaders.mnpPage.topHeader.detail}
              </SectionHeading>
            </>
          )}
          {pageHeaders.mnpPage.privilegeHeader && privileges.length > 0 && (
            <SectionHeading className="mb-4" variant="button-line-through">
              {pageHeaders.mnpPage.privilegeHeader.name}
            </SectionHeading>
          )}
          {privileges.length > 0 && (
            <DataLayerScope
              {...createScopeProps('TMHMoveToTruePrivilegeShelf', {
                privileges,
                pageHeaders,
              })}
            >
              <PrivilegeShelf privileges={privileges} />
            </DataLayerScope>
          )}
        </div>

        {pageHeaders.mnpPage.postpaidPackageHeader &&
          mobilePostpaidPackages.length > 0 && (
            <div className="my-5">
              <>
                <SectionHeading variant="underline" tag="h2">
                  {pageHeaders.mnpPage.postpaidPackageHeader.name}
                </SectionHeading>
                <SectionHeading className="mt-3 mb-4" level="2">
                  {pageHeaders.mnpPage.postpaidPackageHeader.detail}
                </SectionHeading>
              </>

              <DataLayerScope
                {...createScopeProps('MobilePackage', {
                  mobilePostpaidPackages,
                  header: pageHeaders.mnpPage.postpaidPackageHeader,
                })}
              >
                <MobilePackage
                  variant="extended-card"
                  packages={mobilePostpaidPackages}
                />
              </DataLayerScope>
            </div>
          )}
        {pageHeaders.mnpPage.prepaidPackageHeader &&
          mobilePrepaidPackages.length > 0 && (
            <div className="my-5">
              <>
                <SectionHeading variant="underline" tag="h2">
                  {pageHeaders.mnpPage.prepaidPackageHeader.name}
                </SectionHeading>
                <SectionHeading className="mt-3 mb-4" level="2">
                  {pageHeaders.mnpPage.prepaidPackageHeader.detail}
                </SectionHeading>
              </>

              <DataLayerScope
                {...createScopeProps('MobilePackage', {
                  mobilePrepaidPackages,
                  header: pageHeaders.mnpPage.prepaidPackageHeader,
                })}
              >
                <MobilePackage
                  variant="extended-card"
                  packages={mobilePrepaidPackages}
                />
              </DataLayerScope>
            </div>
          )}
        {pageHeaders.mnpPage.deviceHeader && deviceManagements.length > 0 && (
          <Section.Background className="py-5" circle>
            <>
              <SectionHeading variant="underline" tag="h2">
                {pageHeaders.mnpPage.deviceHeader.name}
              </SectionHeading>
              <SectionHeading className="mt-3 mb-4" level="2">
                {pageHeaders.mnpPage.deviceHeader.detail}
              </SectionHeading>
            </>

            <DataLayerScope
              {...createScopeProps('DeviceShelf', {
                deviceManagements,
                header: pageHeaders.mnpPage.deviceHeader,
              })}
            >
              <DeviceShelf
                devices={deviceManagements}
                viewAll={viewAllManagement.deviceViewAll}
              />
            </DataLayerScope>
          </Section.Background>
        )}
        {videoContent && (
          <Section.Background className="py-5" image="/images/kyRvN.svg">
            {pageHeaders.mnpPage.videoContentHeader && (
              <>
                <SectionHeading color="white" tag="h2">
                  {pageHeaders.mnpPage.videoContentHeader.name}
                </SectionHeading>
                <SectionHeading className="mt-3 mb-4" color="white" level="2">
                  {pageHeaders.mnpPage.videoContentHeader.detail}
                </SectionHeading>
              </>
            )}
            <VideoContent content={videoContent} />
          </Section.Background>
        )}
        {contactChannel && contactChannel.contactDetails.length > 0 && (
          <div className="my-5">
            {pageHeaders.mnpPage.contactChannelHeader && (
              <>
                <SectionHeading variant="underline" tag="h2">
                  {pageHeaders.mnpPage.contactChannelHeader.name}
                </SectionHeading>
                <SectionHeading className="mt-3 mb-4" level="2">
                  {pageHeaders.mnpPage.contactChannelHeader.detail}
                </SectionHeading>
              </>
            )}
            <DataLayerScope
              {...createScopeProps('TMHMoveToTrueContactChannel', {
                contactDetails: contactChannel.contactDetails,
                header: pageHeaders.mnpPage.contactChannelHeader,
              })}
            >
              <ContactChannel channels={contactChannel.contactDetails} />
            </DataLayerScope>
          </div>
        )}
        {faq && faq.faqs.length > 0 && (
          <Section.Background className="py-5" color="orange" circle>
            {pageHeaders.mnpPage.faqHeader && (
              <>
                <SectionHeading color="white" tag="h2">
                  {pageHeaders.mnpPage.faqHeader.name}
                </SectionHeading>
                <SectionHeading className="mt-3 mb-4" color="white" level="2">
                  {pageHeaders.mnpPage.faqHeader.detail}
                </SectionHeading>
              </>
            )}
            <DataLayerScope
              {...createScopeProps('Faq', {
                faq,
                header: pageHeaders.mnpPage.faqHeader,
              })}
            >
              <FaqGrid color="orange" topics={faq.faqs} />
            </DataLayerScope>
          </Section.Background>
        )}
        {bottomBanners.length > 0 && <BottomBanner banners={bottomBanners} />}
        {footerHeaders && <Footer className="pb-4" items={footerHeaders} />}
        {chatShop && (
          <ChatShop
            content={chatShop}
            mariPlatform={ serverData.locale == 'th' ? "mari_tmh" : "truemoveh" }
            mariConfig={mariConfig}
          />
        )}
        <CookiesConsent />
      </DataLayerProvider>
    </>
  )
}

export default TrueMoveHMoveToTruePage

export const getServerData = async ({ pageContext }) => {
  let contents = await strapi.getContents(
    [
      'seo',
      'header-script',
      'header-page',
      'brand-headers',
      'product-services',
      'mnp-top-banners',
      'mnp-shortcut-menus',
      'mnp-privileges',
      'mnp-postpaid-packages',
      'mnp-prepaid-packages',
      'device-managements?category=mnp',
      'view-all-management',
      'mnp-video-contents',
      'mnp-contact-channels',
      'mnp-faqs',
      'bottom-banners?page_group.slug=move-to-true',
      'footer-headers',
      'chat-and-shop',
      'mari-chat-and-shop-config',
    ],
    pageContext.locale
  )

  return {
    props: { contents, locale: pageContext.locale },
  }
}
